/** from base.scss **/
div.debug {
  width: 100%;
  display: none;
}

div.listing {
  span {
    margin-left: 10px;
  }

  li {
    list-style: none;
  }

  .image img {
    width: 100px;
  }
}

// color my votes differently
.my_vote {
  background-color: #444 !important;
}
.normal_vote {
  background-color: #aaa !important;
}

/** from App.scss **/

.App-card {
  margin: 1em;
  width: 90%;
  padding: 1em;
  /*
	display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  */
}

.cloud-wrapper {
  border: 1px solid Gainsboro;
  text-align: center;
  background-color: #101030; //midnightblue; //darkslategrey; // #3d768e; //#4d4642;
  padding: 20px;
}
a.github-link {
  margin-left: 7px;
  color: GhostWhite;
}
a.beta-link {
  color: pink !important;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
#welcome a {
  color: blue;
}
.input_box {
  // border: 1px solid #eee;
  padding: 5px;
  align-items: 'left';
  width: '500px';
}
.search_inputs {
  border: 1px solid #ccc;
  background-color: #fafafa;
  align-items: 'left' !important;
}
.accordion .a {
  color: #f00 !important;
}

#venntooltip {
  position: absolute;
  text-align: center;
  width: auto;
  height: 26px;
  background: #333;
  color: #ddd;
  padding: 2px;
  border: 0px;
  border-radius: 8px;
  opacity: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}
/* Keep popover in one place on network view , chakra-popover__content */
.pop_net {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 1000;
}

/* Unvisited Link */
a:link,
.chakra-accordion__panel a:link {
  color: #0000ff;
}

/* Visited Link */
.chakra-accordion__panel a:visited,
a:visited {
  color: #5f03ff;
}

/* Mouse Over Link */
.chakra-accordion__panel a:hover,
a:hover {
  color: #2706e2;
  text-decoration: underline;
}

/* Click on Link */
.chakra-accordion__panel a:active,
a:active {
  color: #078686;
}
